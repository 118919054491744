// src/UnderConstruction.js

import React, { useState } from 'react';

import './UnderConstruction.css'; // For styles
import backgroundImage from './blue.jpeg';

function UnderConstruction() {

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };
  
    return (
      <div className="background" style={{ backgroundImage: `url(${backgroundImage})` }} onMouseMove={handleMouseMove}>
        <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.3)' }}></div>
        <div className="mask" style={{ '--x': `${position.x}px`, '--y': `${position.y}px` }}></div>
        <div className="content">
          <h1 className="pulse">Site Under Construction</h1>
          <p>We are working hard to bring you a new experience!</p>
        </div>
        <footer>
          Powered by <a href="#">Azure Digitalogy Consulting</a>
        </footer>
      </div>
    );
  }

export default UnderConstruction;
